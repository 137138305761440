// top strip

.argumentmap{
	.top_strip{ position: fixed; top: 0; left: 0; right: 0; padding: 8px 20px; background-color: $white; box-shadow: 0 0 5px $gray; z-index: 4; font-size: 13px;
		label{ font-weight:600 }
		.brand_logo{ padding: 5px 4px; background: #fff; position: relative; top: -2px;}
		.top_strip-arrange{ display:flex; justify-content: space-between; align-items: center;
			h5{ margin:0; width: 25vw; text-align: center; background-color: #F7FAFC; padding: 7px; font-size: 18px; position: absolute; left: 50%; transform: translateX(-50%)}
			.top_right-action{display:flex; justify-content: flex-end; align-items: center; padding: 5px 0;
				.divider{ width:2px; height:35px; background:rgba($gray, .5) }
				.mx-1{ margin:0 7px }
			}
			.black_action{
				>a, button{color: #ffffff; transition: .3s all ease; background-color: #1f2030; border: 1px solid #E4ECF3; border-radius: 4px; padding:5px 10px; transition: .5s all ease;
				}
				button{ padding-right: 25px; border: none !important;
					&:after{ display:none }
					&:focus{ outline:none; box-shadow:none !important }
				}
				&.no-carat{
					a{padding:5px 10px !important; position: relative;
						.filternum{ position:absolute; right:-5px; top:-5px; border:1px solid $white }
					}
					&:after{ display:none !important}
				}
				&:hover, &.open{ 
					>a{background:#8688A3;}
					/* &:after{ transform:rotate(-45deg) translateY(4px) translateX(-2px) }*/
				}
				&.open{ 
					&:after{ transform:rotate(-45deg) translateY(4px) translateX(-2px) }
				}
				.dropdown-menu{ padding:0; min-width: 100px;
					.dropdown-item{ font-size:14px; padding:.25rem .5rem;
						&:hover{ background:none; opacity:.5 }
					}
				}
    		}
		}
		.searchWrapper {border: 1px solid rgba($gray, .5); border-radius: 4px; min-height: 22px; padding: 2px 5px; position: relative; max-height: 200px; overflow-y: auto; overflow-x: hidden;}
		.filternum{background-color: #ff3524; color: $white; font-size: 9px; text-align: center; min-width: 16px; height: 16px; display: inline-block; margin-left: 3px; font-weight: bold; border-radius: 100%; line-height: 14px;vertical-align: super;}
	}

	.wrapper-dropdown{ position: relative;
		&.lg{
			>a, .dropdown{ min-width: 160px; font-weight:600}
			>a{ padding: 5px 20px 5px 10px; border: 1px solid #E4ECF3; border-radius: 4px;}
		}
		&.sm{
			>a, .dropdown{}
			>a{ padding: 5px 30px 5px 13px !important;}
		}
		&.dark{ 
			&:after { border-color: $white;}
		}
		&:after {content: ""; width: 6px; height: 6px; position: absolute; right: 13px; top: 50%; margin-top: -6px; border-width: 2px 2px 0 0; border-style: solid; border-color: $black1; transform: rotate(135deg) translateY(-1px); transition: .2s all ease;}
		&.show{
			&:after {transform:rotate(-45deg) translateY(3px) translateX(-3px);}
		}
		.dropdown {visibility: hidden; opacity: 0; position: absolute; transition: all 0.5s ease; left: 0; display: none; margin: 0; padding-left: 0; background-color: #F7FAFC; box-shadow: 0 2px 5px $gray; border-radius: $br4;
			li {clear: both; width: 100%; border-bottom: 1px solid #E4ECF3;
				a{display: block; padding: 7px 20px 7px 10px; background: url('../images/check-icon.png') no-repeat; background-position: calc(100% - 10px) center; color: $black1; 
					img{ width:16px; margin:0 5px 0 0; vertical-align: middle; }
					&:hover{ color:rgba($black1, .5) }
				}
				&:last-child{ border-bottom:0 }
			}
		} 
		>a{display: block;}
		&:hover{
			.dropdown {visibility: visible; opacity: 1; display: block; } 
		}
	}

	.switch {position: relative; display: inline-block; width: 40px; height: 20px; margin-left: 3px; vertical-align: bottom;
		input {opacity: 0; width: 0; height: 0; } 
		.slider {position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #ccc; -webkit-transition: .4s; transition: .4s;
			&:before {position: absolute; content: ""; height: 16px; width: 16px; left: 2px; bottom: 2px; background-color: white; -webkit-transition: .4s; transition: .4s; } 		
			&.round {border-radius: 34px; } 
			&.round:before {border-radius: 50%; }
		}
		input:checked + .slider {background-color: #2196F3; } 
		input:focus + .slider {box-shadow: 0 0 1px #2196F3; } 
		input:checked + .slider:before {-webkit-transform: translateX(20px); -ms-transform: translateX(20px); transform: translateX(20px); } 
	}
	/* Rounded sliders */ 
	.multiSelectContainer li{ color:$black1; font-size:12px;
		&.highlightOption, &:hover{ color:$white }
	}
	.multiselect-container{
		.chip{ font-size: 11px; line-height: 14px;}
		.chip, .singleChip{white-space: normal;}
		.notFound{ color:$black1 }
	}
	.optionListContainer{ background: #F7FAFC;
		ul{
			li{
				&.highlightOption, &:hover{ background:$white; color:$black1;
					&.disableSelection{ opacity:1 }
				}
				input{ float:right; margin-right:0 }
			}
		}
	}
}

.argumentmap{
	.page_option{position: fixed; right: 50px; bottom: 30px; font-size: 12px; z-index: 2;
		.zoom{ background:#141A21; color:$white; border-radius: $br4; display: flex; align-items: center;
			a{ color:$white; padding-left: 3px; padding-right: 3px}
			>div{ min-width: 35px; text-align: center; }
			>a{ color:$white; font-size:18px; padding:4px 7px 7px; font-weight:600;
				&:first-child{ border-right:1px solid $black1 }
				&:last-child{ border-left:1px solid $black1 }
			}
			&.up_down{ overflow: hidden;
				a{ line-height:100%; font-size:13px; position: relative; padding:  5px 8px 10px 8px;
					img{ width:10px }
					&:first-child{  transform: scale(1, 1.1); }
					&:last-child{ transform: scale(1, 1.1);}
				} 
			}
		}
		.arrange_horrizontal{ display:flex; align-items:center; }
	}

	.element_info{ position:absolute; left:30px; top:75px; max-width: 200px; z-index: 3;
		>a{ display:inline-block; padding:10px 15px 0px 0px; position:relative;
			&:after{content: ''; position:absolute; right:0px; top:15px; width: 6px; height: 6px; border-width: 2px 2px 0 0; border-style: solid; border-color: rgba($black1, .5); transform: rotate(135deg);}
			img{ margin-right:2px; vertical-align:bottom }
			span{ font-size:16px }
		}
		p{ margin-bottom:20px; margin-top: 2px; font-size: 80%; line-height: 120%; }
		ul{ margin-bottom: 0;max-width: 180px; display:none;
			li{ margin-bottom: 10px;
				&.contention, &.reasons, &.evidence, &.objection, &.counter_evidence{
					a{ display: block; padding: 10px 30px; border-radius: $br4; text-align: center; color:$white; cursor: pointer; cursor: default;
						img{}
						strong, span{ display:inline-block; width:100%}
						span{ opacity:.7 }
						&:hover{ color:$white }
					}
				}
				&.contention{
					a{ background-color: $blue;
						&:hover{background-color: rgb(142, 106, 188,);}
					}
				}
				&.reasons{
					a{ background-color: $green;
						&:hover{background-color: rgb(72, 171, 120);}
					}
				}
				&.evidence{
					a{ background-color: $light_green; color: $green !important;
						&:hover{background-color: rgb(178, 241, 203);}
					}
				}
				&.objection{
					a{ background-color: $red;
						&:hover{background-color: rgb(247, 109, 93);}
					}
				}
				&.counter_evidence{
					a{ background-color: $light_red; color: $red !important;
						&:hover{background-color: rgb(250, 206, 202);}
					}
				}
			}
		}
		&:hover{
			ul{display:block;}
		}
	}
}

.argumentmap{
	.top_strip{
		.filter_section{position: absolute; right: 20px; top: 45px;
			.card{ width:400px; padding: 0; box-shadow: 3px 15px 30px 0 rgba($black, .25); border: none;
				.card-header, .card-footer{ background-color:$white; }
				.card-header{
					h5{ font-size:14px; color:$black1;
						img{ width:18px; height:auto }
					}
					a{ color:$skyblue; font-size:12px;
						span{ margin-right:5px }
					}
				}
				.card-body{ max-height: calc(100vh - 150px); min-height: 250px; /*overflow-x: visible; overflow-y: auto;*/
					label{ color:$black1 }
					.search{ background:url('../images/search.png') no-repeat; background-size: 18px auto; background-position: calc(100% - 7px) center; padding-right:30px;
						&:focus{ box-shadow: none; border-color: #ced4da}
					}
				}
				.card-footer{
					a{ color:$skyblue;
						img{ width:12px; height:auto }
						span{ vertical-align:middle }
					}
					.btn{ background:#1f2030; color:$white; padding:4px 20px 7px 20px; margin-right: 0}
				}
			}
		}

		.search_section{ margin:0; text-align: center; background-color: $white; padding: 2px; font-size: 18px; position: absolute; left: 50%; transform: translateX(-50%);
			.search_field{ background-color:$white; padding:5px; /*box-shadow: 3px 15px 30px 0 rgba($black, .25);*/ border-top: 0; border-radius:0 0 4px 4px; transition: .3s ease all; width: 300px; position:relative;
				input{ background:url('../images/search.svg') #F4F8FB no-repeat; border-color:#F4F8FB; background-size: 18px auto; background-position: 6px center; padding-left:30px;
					&::-webkit-input-placeholder {color: #333; } 
					&:-moz-placeholder {color: #333; } 
					&::-moz-placeholder {color: #333; } 
					&:-ms-input-placeholder {color: #333; } 
					&:focus::-webkit-input-placeholder {color: #ddd; } 
					&:focus:-moz-placeholder {color: #ddd; } 
					&:focus::-moz-placeholder {color: #ddd; } 
					&:focus:-ms-input-placeholder {color: #ddd;}
					&:hover::-webkit-input-placeholder {color: #ddd; } 
					&:hover:-moz-placeholder {color: #ddd; } 
					&:hover::-moz-placeholder {color: #ddd; } 
					&:hover:-ms-input-placeholder {color: #ddd;}
					&:focus{ box-shadow:none; outline:0; border-color:#ced4da; }
					&:hover{ border-color:#6CB0F5 }
				}
				span{ position: absolute; right: 10px; top: 9px; background: #e7e8ee; font-size: 12px; border-radius: 4px; padding: 3px 3px 2px 3px; }
			}
		}
	}

	.kpifeature_section{background-color: #F0F5F8; padding: 10px 20px 10px 260px; position: relative; left: -30px; width: calc(100% + 60px); top: 63px; z-index: 3;
		ul{ display: flex; align-items: center; justify-content: end; margin-bottom: 0;
			li{ 
				>div{ position: relative; background-color: $white; padding: 8px; border-radius: $br4 $br4 0 $br4; min-width: 185px; margin-left: 10px;overflow: hidden;
					a{ position:absolute; left:0; top:0; width:100%; height:100%; }
					.kpifeature_cnt{ display: flex; align-items:center; justify-content: space-between; 
						.kpifeature_txt{
							span{ color:#7E8B9F; font-size:10px;line-height: 100%; }
							.strong{ font-weight:700; font-size:20px; padding:3px 0; line-height: 100%; }
						}
						.kpifeature_img{position: relative; z-index: 1;
							span{ display:inline-block; padding:12px; border-radius:$br4;
								img{ width:20px; height:auto }
							}
							img{ width:62px; height:auto }
						}
					}
				}
				&:first-child{
					>div{ margin-left:0 }
				}
				&.copy{
					.kpifeature_cnt{overflow: hidden;
						.kpifeature_img{position: relative; z-index: 1;}
						&:after{ content:'';  position:absolute; right: -86px; top: -2px; width: 150px; height: 150px; z-index: 0; background: #FAFBFC; transform: rotate(130deg); }
					}
				}
				.both{
					.kpifeature_cnt{ border-right:1px solid #f0f5f8; margin-right: 15px; width: 50%;
						&:last-child{ border-right:0 !important; margin-right:0 }
					}
				}
				&.reasons{
					>div{ 
						.kpifeature_cnt{
							.kpifeature_txt{
								.txt{ color:#00D077 }
							}
							.kpifeature_img{
								span{ background-color:rgba(13, 226, 111, .05) }
							}

						}
					}
				}
				&.demand{
					>div{ 
						.kpifeature_cnt{
							.kpifeature_txt{
								.txt{ color:#0082FA }
							}
							.kpifeature_img{
								span{ background-color:rgba(0, 130, 250, .05) }
							}

						}
					}
				}
				&.objection{
					>div{ 
						.kpifeature_cnt{
							.kpifeature_txt{
								.txt{ color:#F54834 }
							}
							.kpifeature_img{
								span{ background-color:rgba(245, 72, 52, .05) }
							}

						}
					}
				}
				&.chart{
					.both{display: flex; align-items: center; justify-content: space-between;
						/*.kpifeature_cnt{ border-right:1px solid;
							&:last-child{ border-right:0 !important }
						}*/
					}
					>div{ 
						.kpifeature_cnt{
							&.active, &.unactive{
								.kpifeature_img{
									img{ width:36px }
								}
							}
							&.active{
								.kpifeature_txt{
									.txt{color: #06B17E}
								}									
							}
							&.unactive{
								.kpifeature_txt{
									.txt{color: #7E8B9F}
								}									
							}
							.kpifeature_txt{
								span{ color:#A5B0C2 }
							}

						}
					}
				}
			}
		}
	}
}



@media only screen and (min-width: 1400px) {
	.argumentmap{ 
		.top_strip{ 		
		}
		.kpifeature_section{
		 	ul{
			 	li{width:20%;
				&.chart{ min-width:315px; }
			 		> div{ min-width: 205px;}
			 	}
			}	
		}
	}
}
@media only screen and (max-width: 1400px) {
	.argumentmap{ 
		.top_strip{ 
			.search_section{ margin-left: -50px;
				.search_field{ width:18vw}
			}			
		}
	}
}

@media only screen and (max-width: 1300px) {
	.argumentmap{
		.top_strip{
			.top_strip-arrange{
				h5{ width:20vw }
			}
			.search_section{ margin-left: -50px;
				.search_field{ width:18vw}
			}
		} 
		.kpifeature_section{
		 	ul{
			 	li{ 
				&.chart{ min-width:315px; }
			 		> div{ min-width: 160px;}
			 	}
			}	
		}
	}
}

@media only screen and (max-width: 1250px) {
	.argumentmap{padding: 80px 15px 30px;
		.top_strip{ padding: 2px 15px;
			.top_strip-arrange{
				h5{ width:15vw }
			}
			.search_section{position: relative; left: 0%; transform: translateX(0%);
				.search_field{ width:25vw}
			}
		}
		.kpifeature_section{ padding: 10px 15px; top: -20px; left: -20px;
		 	ul{
			 	li{ width:20%;
				&.chart{ min-width:315px; }
			 		> div{ min-width: 160px;}
			 	}
			}	
		}
	}
	.element_info{z-index:2 !important}
}

@media only screen and (max-width: 1100px) {
	.argumentmap{
		.top_strip{
			.top_strip-arrange{
				h5{ width:auto }
			}
			.search_section{
				.search_field{width:auto}
			}
		}
		.wrapper-dropdown.lg > a{ min-width:140px }
	}
} 